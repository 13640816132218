import { useContext, useEffect, useRef, useState } from 'react';

import axios from 'axios';

import { useParams } from 'react-router-dom';
import { AppContext } from '../App';

import VoteEnd from './topic/VoteEnd';

import styled from 'styled-components';
import QuestionAndAnswer from './topic/QuestionAndAnswer';
import StyledContentPanel from '../components/StyledContentPanel';
import TypingTextV2 from '../components/TypingTextV2';
import SliderPanel from './topic/SliderPanel';
import { animated, useSpring } from 'react-spring';

const VoteScreenContainer = styled(animated.div)`
    width: 100%; 
    display: flex;
    flex-direction: column; 
    transition: height 1s ease;
    ${(props: { topicstate: TopicState }) => `
        height: ${props.topicstate === TopicState.Initial ? '100%' : 'calc(50vh + 200px)'};
    `}
`;

export enum VoteState {
    Initial = 'initial',
    Touched = 'touched',
    Moved = 'moved',
}

enum TopicState {
    Initial = 'initial',
    SliderTransform = 'slider-transform',
    VoteResults = 'vote-result', // containes first question
    QnA = 'qna', // after first question hide title and results
    EndScreen = 'vote-end'
}

enum TopicAnimationState {
    ContentPanelFadeIn = 'content-panel-fade-in',
    TypeTitleText = 'type-title-text',
    SliderFadeIn = 'slider-fade-in',
    TitleAndSliderFadeOut = 'title-and-slider-fade-out'
}

interface TopicScreenState {
    topicState: TopicState;
    topicAnimationState: TopicAnimationState;
    voteValue: number | null;
    voteId: number | null;
}

const initState: TopicScreenState = {
    topicState: TopicState.Initial,
    topicAnimationState: TopicAnimationState.ContentPanelFadeIn,
    voteValue: null,
    voteId: null,
};

const TopicComponent: React.FC = () => {

    const { apiUrl, topic, setTopic, setGlassPanelBackdropFilter } = useContext(AppContext);
    const { topicHash }: { topicHash: string } = useParams();

    const [state, setState] = useState<TopicScreenState>(initState);
    const stateRef = useRef<TopicScreenState>(state);
    useEffect(() => { stateRef.current = state; }, [state]);

    const [voteScreenStyle, voteScreenApi] = useSpring(() => ({
        opacity: 1,
        config: { duration: 300 },
    }));

    const [titlePanelStyle, titlePanelApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    const [sliderPanelStyle, sliderPanelApi] = useSpring(() => ({
        opacity: 0,
        flexGrow: 1,
        config: { duration: 600 },
    }));

    const getTopic = async () => {
        const res = await axios.get(`${apiUrl}/topic/${topicHash}/itself`);
        setState(initState);

        const topic = res.data.origMsg;
        topic.entityLogo = res.data.entityLogo;

        setTopic(topic);

        titlePanelApi.start({
            opacity: 1,
            onRest: () => {
                setState({
                    ...state,
                    topicAnimationState: TopicAnimationState.TypeTitleText
                })
            }
        });
    };

    const resetVote = () => {
        setState(initState);
        voteScreenApi({ opacity: 1, immediate: true });
        sliderPanelApi({ opacity: 0, immediate: true });

        titlePanelApi.start({
            from: { opacity: 0 },
            opacity: 1,
            onRest: () => {
                setState({ ...stateRef.current, topicAnimationState: TopicAnimationState.TypeTitleText })
            }
        });
    };

    useEffect(() => {
        getTopic();
    }, [topicHash]);

    const voteTitle = !topic ? '' : topic.tdsn[0].toUpperCase() + topic.tdsn.slice(1);

    const submitValue = (voteValue: number, voteId: number) => {
        stateRef.current = { ...stateRef.current, topicState: TopicState.SliderTransform, voteValue, voteId };
        setState(stateRef.current);
    };

    const firstQuestionAnswered = () => {
        voteScreenApi.start({
            opacity: 0,
            onRest: () => { setState({ ...state, topicState: TopicState.QnA }); }
        });
    };

    return (!topic ? null : // <h4>Зареждане...</h4> :
        <>
            {!(state.topicState === TopicState.Initial || state.topicState === TopicState.VoteResults || state.topicState === TopicState.SliderTransform) ? null :
                <VoteScreenContainer topicstate={state.topicState} style={voteScreenStyle}>
                    <animated.div style={{ padding: '20px', paddingBottom: '10px', ...titlePanelStyle }}>
                        <StyledContentPanel>
                            <TypingTextV2 active={state.topicAnimationState === TopicAnimationState.TypeTitleText} delay={10} onComplete={() => {
                                setGlassPanelBackdropFilter(40, 1, 1);
                                sliderPanelApi.start({
                                    opacity: 1,
                                    onRest: () => {
                                        stateRef.current = { ...stateRef.current, topicAnimationState: TopicAnimationState.SliderFadeIn };
                                        setState(stateRef.current);
                                    }
                                })
                            }}>
                                <h1>{voteTitle}</h1>
                                <p className='auxiliary' style={{ marginTop: '10px' }}>кажете с плъзгане на топчето</p>
                            </TypingTextV2>
                        </StyledContentPanel>
                    </animated.div>
                    <animated.div style={{ position: 'relative', ...sliderPanelStyle }}>
                        <SliderPanel
                            voteValue={state.voteValue}
                            submitValue={submitValue}
                            onAnimationComplete={() => setState({ ...state, topicState: TopicState.VoteResults })}
                        />
                    </animated.div>
                </VoteScreenContainer>
            }
            {!(state.topicState === TopicState.VoteResults || state.topicState === TopicState.QnA) ? null :
                <div style={{ padding: '20px' }}>
                    <QuestionAndAnswer
                        voteValue={state.voteValue}
                        voteId={state.voteId}
                        firstQuestionAnswered={firstQuestionAnswered}
                        setVoteEnd={() => setState({ ...state, topicState: TopicState.EndScreen })}
                    />
                </div>
            }
            {!(state.topicState === TopicState.EndScreen) ? null :
                <div style={{ padding: '20px' }}>
                    <VoteEnd resetVote={resetVote} />
                </div>
            }
        </>
    );
};

export default TopicComponent;