import styled from "styled-components";

const StyledContentPanel = styled.div`
    background-color: #00000055;
    padding: 20px;
    color: white;
    border-radius: 20px;
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    box-sizing: border-box;
    width: 100%;

    h1 {
        margin: 0;
        font-size: 24px;
    }

    p {
        font-size: 20px;
        color: white;
        margin-top: 15px;
        margin-bottom: 0;

        &.auxiliary {
            font-size: 16px;
        }
    }
`;

export default StyledContentPanel;