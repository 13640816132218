import React, { useContext, useRef, useState } from 'react';

import axios from 'axios';
import { AppContext } from '../../../App';
import { DeleteImageFunction, SetImageFunction } from './OpenStructure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import StyledButton from '../../../components/StyledButton';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

import styled from 'styled-components';

const ApproveImagePanel = styled.div`
    background-color: #00000055;
    padding: 20px;
    color: white;
    border-radius: 20px;
    display: inline-block;
    font-size: 24px;
    margin-bottom: 20px;
    width: 100%;

    img {
        width: 100%;
        border-radius: 20px;
        margin-bottom: 20px;
    }
`;

interface UploadPhotoProps {
    number: number;
    value: { text: string; image: string | null };
    setImage: SetImageFunction;
    deleteImage: DeleteImageFunction;
}

export default (props: UploadPhotoProps) => {
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);
    const { apiUrl, topic } = useContext(AppContext);

    const imageInputRef = useRef<HTMLInputElement>(null);

    const onImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            setImageBlob(file);
            uploadImage();
        };
    };

    const uploadImage = async () => {
        const formData = new FormData();

        if (!imageBlob) return;

        formData.append('image', imageBlob);

        const res = await axios.post(`${apiUrl}/qna/${topic?.id}/img_upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        console.log(res.data.url);

        props.setImage(props.number, res.data.url);
    };

    const deleteImage = async () => {
        setImageBlob(null);
        //props.deleteImage(props.number);
    };

    const handleButtonClick = () => {
        if (imageInputRef.current)
            imageInputRef.current.click();
    };

    return (
        <>
            {!props.value.image ?
                <>
                    <input
                        ref={imageInputRef}
                        type="file"
                        id="myfile"
                        name="myfile"
                        onChange={onImageSelect}
                        style={{ display: 'none' }}
                    />

                    {!imageBlob ?
                        <StyledButton onClick={handleButtonClick}>
                            <FontAwesomeIcon icon={faCamera} /> Качи изображение
                        </StyledButton> :
                        <ApproveImagePanel>
                            <img src={URL.createObjectURL(imageBlob)} />
                            <StyledButton onClick={deleteImage} style={{ marginBottom: 0 }}>
                                <FontAwesomeIcon icon={faTrash} /> Изтрий изображение
                            </StyledButton>
                        </ApproveImagePanel>
                    }
                </> :
                <>
                    <img src={props.value.image} />
                    <h5>
                        Съобщението е успешно записано на сървъра
                        {props.value.image}
                    </h5>
                </>
            }

        </>
    );
};