import { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { AppContext } from '../App';
import TopicCard from '../components/TopicCard';

import { Topic } from 'holler-voterapp-model';
import TypingText from '../components/TypingText';
import StyledContentPanel from '../components/StyledContentPanel';
import { useHistory } from 'react-router-dom';
import { generateTopicSlug } from '../util/util';

export default () => {
    const [topics, setTopics] = useState<Topic[] | null>(null);
    const { apiUrl, setTopic, setGlassPanelColor, setGlassPanelBackdropFilter } = useContext(AppContext);

    const history = useHistory();

    const loadTopics = async () => {
        const topicData = await axios.get(`${apiUrl}/topic/now/children`);

        setTopics(topicData.data.msgList.filter((topic: Topic) => topic.tn !== 'now'));
    };

    useEffect(() => {
        setGlassPanelColor('');
        setGlassPanelBackdropFilter(0, 1, 1);
        setTopic(null);
        loadTopics();
    }, []);

    return (<div style={{ padding: '20px' }}>
        <StyledContentPanel style={{ marginBottom: '20px' }}>
            <TypingText text={"Здравейте, с нас виждате какво казва България за:"} delay={20} />
        </StyledContentPanel>
        {!topics ? null : topics.map((topic, i) => <TopicCard key={i} topic={topic} onClick={() => { history.push(generateTopicSlug(topic.tn)) }} />)}
    </div>);
};
