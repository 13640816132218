import { Topic } from 'holler-voterapp-model';

import styled from 'styled-components';

const TopicCardStyle = styled.button`
    width: 100%;;
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    vertical-align: top;
    margin: 10px 0;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    background-color: #00000055;
    color: white;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: left;

    img {
        width: 100%;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #999;
        margin: 0;
        padding: 20px 20px 20px 20px;
    }

    h2 {
        font-weight: 444;
        font-size: 22px;
        margin: 20px 0;
        padding: 10px 20px 15px 20px;
    }
`;

export const TopicCardSkeleton = styled.div`
    width: calc(100% / 3 - 20px);
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    vertical-align: top;
    margin: 10px;
    text-decoration: none;
    color: #000;
    height: 330px;
    background-color: white;
`;

export default (props: { topic: Topic, onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {

    const voteTitle = props.topic.tdsn[0].toUpperCase() + props.topic.tdsn.slice(1);

    return (
        <TopicCardStyle onClick={props.onClick}>
            <img src={props.topic.ti} />
            <h2><b>{voteTitle}?</b></h2>
        </TopicCardStyle>
    );
};