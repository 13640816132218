import { useContext } from "react";

import StyledContentPanel from "../components/StyledContentPanel";
import { AppContext, MenuScreen } from "../App";
import StyledButton from "../components/StyledButton";

const LanguageScreen: React.FC = () => {

    const { setMenuScreen } = useContext(AppContext);

    return (
        <div style={{ padding: '20px'}}>
            <StyledContentPanel style={{ marginBottom: '20px' }}>
                <h1>Изберете език</h1>
            </StyledContentPanel>
            <StyledButton onClick={() => setMenuScreen(MenuScreen.MainMenu)}>
                Назад
            </StyledButton>
            <StyledButton onClick={() => setMenuScreen(MenuScreen.MainMenu)}>
                Български
            </StyledButton>
        </div>
    );
};

export default LanguageScreen;