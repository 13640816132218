
import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import App, { AppContext, MenuScreen, ProfileData } from '../App';

import styled from 'styled-components';
import StyledContentPanel from '../components/StyledContentPanel';
import StyledButton from '../components/StyledButton';
import StyledDropdown from '../components/StyledDropdown';
import StyledTextInput from '../components/StyledTextInput';

const ProfileStyle = styled.div`
    padding: 20px;
`;

const ProfileDivider = styled.hr`
    margin: 20px 0;
    height: 1px;
    border: none;
    background-color: #ddd;
`;

interface ProfileFormData {
    locale: string;
    name: string;
    gender: 'male' | 'female' | 'other' | null;
    birthYear: number | null;
    cityName: string;
    regionName: string;
    countryName: string;
}

const ProfileComponent: React.FC = () => {

    const { setMenuScreen } = useContext(AppContext);

    const initFormData = (profile: ProfileData) => ({
        locale: profile.locale,
        name: profile.name ?? '',
        gender: profile.gender,
        birthYear: profile.birthYear,
        cityName: profile.cityName,
        regionName: profile.regionName,
        countryName: profile.countryName
    });

    const { apiUrl, profile, setProfile, topic } = useContext(AppContext);
    const [formData, setFormData] = useState<ProfileFormData>(initFormData(profile));

    useEffect(() => {
        setFormData({ ...formData, birthYear: profile.birthYear });
    }, [profile.birthYear]);

    useEffect(() => {
        setFormData({ ...formData, gender: profile.gender });
    }, [profile.gender]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const saveChanges = async () => {
        const fieldsToChange: Record<string, unknown> = {};

        for (const fieldKey of Object.keys(formData) as string[]) {
            const formField = formData[fieldKey as keyof typeof formData];
            const profileField = profile[fieldKey as keyof typeof profile];

            if (formField !== profileField && formField)
                fieldsToChange[fieldKey] = formField;
        }

        if (Object.keys(fieldsToChange).length > 0) {
            if (topic)
                fieldsToChange.topicId = topic.id;

            await axios.put(`${apiUrl}/user`, fieldsToChange);
            for (const fieldKey of Object.keys(formData)) {
                (profile as any)[fieldKey as keyof typeof profile] = formData[fieldKey as keyof typeof formData];
            }
            setProfile({ ...profile });
        }
    };

    const cancelChanges = () => {
        setFormData(initFormData(profile));
    };

    const deleteProfile = async () => {
        await axios.delete(`${apiUrl}/user`);
        setProfile(null);
    };

    const locales = [
        { value: 'en_US', name: 'English' },
        { value: 'bg_BG', name: 'български' },
        { value: 'nl_NL', name: 'Nederlands' },
        { value: 'ru_RU', name: 'Ру́сский' },
        { value: 'hy_AM', name: 'Հայերեն' },
        { value: 'fr_FR', name: 'Français' },
        { value: 'de_DE', name: 'Deutsch' },
        { value: 'es_ES', name: 'Español' },
        { value: 'pt_PT', name: 'Português' },
        { value: 'el_GR', name: 'Ελληνικά' },
        { value: 'hr_HR', name: 'Hrvatski' }
    ];

    const genders = [
        { value: 'male', name: 'Мъж' },
        { value: 'female', name: 'Жена' },
        { value: 'other', name: 'Друго' }
    ];

    const birthYears = Array.from(Array(77).keys()).map(n => n + 1940).map(year =>
        ({ value: year, name: year })
    );

    return (
        !profile ? <h5>Зареждане на профил...</h5> :
            <ProfileStyle>
                <StyledContentPanel style={{ marginBottom: '20px' }}>
                    <h1>Профил</h1>
                    <p style={{ fontSize: '15px', margin: '10px'}}>{profile.userId}</p>
                </StyledContentPanel>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.MainMenu)}>Назад</StyledButton>
                <StyledDropdown name='locale' onChange={handleChange}>
                    {locales.map(locale =>
                        <option value={locale.value} selected={formData.locale === locale.value}>
                            {locale.name}
                        </option>
                    )}
                </StyledDropdown>
                <StyledTextInput type='text' placeholder='Име' onChange={handleChange} name={'name'} value={formData.name} />
                <StyledDropdown name='gender' onChange={handleChange}>
                    <option disabled selected={!formData.gender}>Вашият пол</option>
                    {genders.map(gender =>
                        <option value={gender.value} selected={formData.gender === gender.value}>
                            {gender.name}
                        </option>
                    )}
                </StyledDropdown>
                <StyledDropdown name='birthYear' onChange={handleChange}>
                    <option disabled selected={!formData.birthYear}>Годината Ви на раждане</option>
                    {birthYears.map(birthYear =>
                        <option value={birthYear.value} selected={formData.birthYear === birthYear.value}>
                            {birthYear.name}
                        </option>
                    )}
                </StyledDropdown>
                <StyledTextInput type='text' name='cityName' placeholder='Град' onChange={handleChange} value={formData.cityName} />
                <StyledTextInput type='text' name='regionName' placeholder='Район' onChange={handleChange} value={formData.regionName} />
                <StyledTextInput type='text' name='countryName' placeholder='Държава' onChange={handleChange} value={formData.countryName} />
                <StyledButton style={{ marginTop: '30px' }} onClick={saveChanges}>Запазете промените</StyledButton>
                <StyledButton onClick={cancelChanges}>Откажете промените</StyledButton>
                <ProfileDivider />
                <StyledButton onClick={deleteProfile}>Изтрий профил</StyledButton>
            </ProfileStyle>
    );
};

export default ProfileComponent;