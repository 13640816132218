import { useContext } from "react";

import { AppContext, MenuScreen } from "../App";

import styled from "styled-components";
import StyledButton from "../components/StyledButton";
import StyledContentPanel from "../components/StyledContentPanel";

const PolicyScreenStyle = styled(StyledContentPanel)`
    h1 {
        margin: 20px 0;
        font-size: 24px;
    }

    h2 {
        margin: 20px 0;
        font-size: 17px;//24px;
    }

    h3 {
        margin: 20px 0;
        font-size: 17px;
    }

    h4 {
        margin: 20px 0;
        font-size: 15px;
    }

    p, li {
        font-size: 15px;
        color: white;
        margin-top: 15px;
        margin-bottom: 0;
        line-height: 1.7;
        font-weight: normal;
    }
`;

const PolicyScreen: React.FC<{ prevScreen: MenuScreen }> = (props) => {

    const { menuScreen, setMenuScreen } = useContext(AppContext);

    return (
        <div style={{ padding: '20px' }}>
            <PolicyScreenStyle>
                <h1>Политика за защита на личните данни на ЦНТС</h1>
                <h2>ЗА НАС.</h2>
                <p>
                    „ЦНТС“ е компания, предлагаща цялостно обслужване в сферата на маркетинговите и социални изследвания и консултации.
                </p>
                <p>
                    „ЦНТС“ е компания, предлагаща цялостно обслужване в сферата на маркетинговите и социални изследвания и консултации.
                </p>
                <p>
                    „ЦНТС ООД (наричана по-надолу в текста <b>Компанията</b>) има повече от над 15 годишен опит в осигуряването на качествено обслужване
                    на своите клиенти, чрез използването на различни методи за събиране на данни: индивидуални и телефонни интервюта, бизнес изследвания,
                    експертни интервюта, фокус групи и други.
                </p>
                <h2>НОРМАТИВНО ОСНОВАНИЕ.</h2>
                <p>Изготвянето на политиката е съобразено с изискванията на:</p>
                <ul>
                    <li>Закона за защита на личните данни (ЗЗЛД)</li>
                    <li>Общ регламент за защита на личните данни (Регламент (ЕС) 2016/679).</li>
                </ul>
                <h2>ЦЕЛ НА ПОЛИТИКАТА.</h2>
                <p>Да определи правилата по отношение на защитата на физически лица във връзка с обработването на лични данни, като посочи:</p>
                <ul>
                    <li>Видовете лични данни, които се обработват;</li>
                    <li>Начин на събиране на личните данни;</li>
                    <li>Къде следва да се предоставят личните данни и на кого;</li>
                    <li>Как се защитават и съхраняват личните данни.</li>
                </ul>
                <p>Да защити основните права и свободи на физическите лица, и по-специално тяхното право на защита на личните данни.</p>
                <h2>ВИДОВЕ ЛИЧНИ ДАННИ, КОИТО СЕ ОБРАБОТВАТ.</h2>
                <p>
                    Компанията събира, съхранява и използва следните видове информация за физически лица: лични данни за контакт като име,
                    адреси, телефонни номера, служебни и лични имейл адреси. Също така дата на раждане и демографски характеристики
                    (пол, възраст, населено място).
                </p>
                <h2>НАЧИН НА СЪБИРАНЕ НА ДАННИТЕ.</h2>
                <p>
                    Ние събираме информация от вас, когато се регистрирате на нашия сайт, отговаряте на анкета, попълвате формуляр или въвеждате
                    информация на нашия сайт. Предоставяйки ни обратна връзка за продукти или услуги.
                </p>
                <h3>Как използваме вашата информация?</h3>
                <p>
                    Може да използваме информацията, която събираме от вас, когато се регистрирате, правите покупка, абонирате за нашия бюлетин,
                    отговаряте на проучване или маркетингова комуникация, сърфирате в уебсайта или използвате някои други функции на сайта
                    по следните начини:
                </p>
                <ul>
                    <li>
                        За да персонализираме вашето изживяване и да ни позволите да предоставим типа съдържание и продуктови
                        предложения, от които се интересувате най-много.
                    </li>
                    <li>За да подобрим нашия уебсайт, за да ви обслужваме по-добре.</li>
                    <li>За да ни позволите да ви обслужваме по-добре в отговор на вашите заявки за обслужване на клиенти.</li>
                    <li>За администриране на конкурс, промоция, проучване или друга функция на сайта.</li>
                    <li>За да оцените услуги или продукти.</li>
                    <li>За да се свържем с вас в кореспонденция (чат на живо, имейл или телефон)</li>
                </ul>
                <h3>Как защитаваме вашата информация?</h3>
                <p>
                    Нашият уебсайт се сканира редовно за пропуски в сигурността и известни уязвимости, за да направим Вашето посещение на нашия сайт
                    възможно най-безопасно. Използваме редовно сканиране за зловреден софтуер. Вашата лична информация се съдържа зад защитени мрежи
                    и е достъпна само от ограничен брой лица, които имат специални права за достъп до такива системи и са задължени да пазят информацията
                    поверителна. В допълнение, цялата чувствителна/кредитна информация, която предоставяте, е криптирана чрез технологията Secure Socket
                    Layer (SSL). Ние прилагаме различни мерки за сигурност, когато потребител въвежда, изпраща или получава достъп до своята информация,
                    за да поддържаме безопасността на вашата лична информация. Всички транзакции се обработват чрез доставчик на портал и не се съхраняват
                    или обработват на нашите сървъри.
                </p>
                <h3>Какво представляват бисквитките и вашите права относно използването им:</h3>
                <p>
                    Бисквитките са широко използвана технология, състояща се от информация, която уебсайтовете изпращат и съхраняват на вашето устройство.
                </p>
                <p>
                    Уеб браузърите са отговорни за съхраняването на бисквитки и могат също така да помогнат за тяхното управление. Моля, консултирайте се с
                    доставчика на вашия браузър за настройките, които ви позволяват да блокирате нежелани бисквитки. Не можем да гарантираме, че нашият уебсайт
                    ще функционира правилно през браузъри, където бисквитките са блокирани.
                </p>
                <p>
                    Имате право да оттеглите съгласието си за използването на бисквитки от наша страна по всяко време, като използвате нашата
                    функция „Изтриване на моите данни“ под страницата на профила.
                </p>
                <h3>Вашият начин да стигнете до нас</h3>
                <p>
                    Винаги можете да изпратите имейл до нашия DPO Радослав Райков на <b>privacy@cnts.bg</b> за вашите въпроси и искания относно вашите права
                    за защита на поверителността.
                </p>
                <h3>Нашата употреба на бисквитки:</h3>
                <p>
                    Използваме подписана и криптирана бисквитка, наречена „consentioProfile“. Това е постоянна бисквитка, което означава, че има
                    “срок на годност”, който в този случай е 1 година. Това също е бисквитка на първа страна, така че трети страни не могат да я
                    използват, за да идентифицират Вашето устройство.
                </p>
                <p>
                    Нашата бисквитка съдържа криптирана информация за потребителски идентификатор и ни помага да запомним вашето устройство, когато
                    се върне на нашия уебсайт. Това е от съществено значение за нашата услуга и ни позволява да ви покажем вашата история на гласуване,
                    да подобрим изживяването ви, като запомним предпочитанията ви, а също и да създадем нашите анализи на общественото мнение.
                </p>
                <ol>
                    <li>
                        Строго необходима употреба
                        <ul>
                            <li>
                                Удостоверяване на потребителя между нашите различни услуги (технически термин, да не се бърка с услуги за потребителя)
                            </li>
                            <li>
                                Сигурност и цялост на данните за мнения, които събираме - за да гарантираме, че аналитичната информация,
                                която предоставяме на потребителите и клиентите, е автентична и за да противодействаме на злонамерено поведение
                                на нашата платформа
                            </li>
                        </ul>
                    </li>
                </ol>
                <h2>ЦЕЛ И ПРАВНО ОСНОВАНИЕ ЗА ОБРАБОТВАНЕ.</h2>
                <ul>
                    <li>Съгласие – когато е получено ясно съгласие да бъдат обработвани лични данни за определена цел, напр. за участие маркетингови проучвания.</li>
                    <li>Договор – когато обработването на лични данни е необходимо за изпълнението на нашите задължения по договор.</li>
                    <li>Правно задължение – когато обработването на лични данни е необходимо за спазване на правно задължение, напр. за данъчни цели.</li>
                    <li>Законов интерес – когато обработването на лични данни обслужва основателен бизнес интерес, освен ако не е в интерес на лицето тази информация да бъде защитена.</li>
                </ul>
                <h2>ВАШИТЕ ПРАВА.</h2>
                <p>Физическите лица имат редица права по отношение на личните си данни, обработвани от ЦНТС, а именно:</p>
                <ol>
                    <li>
                        Достъп до своите лични данни.
                        <br />
                        Имате правото да получите от нас потвърждение за това дали Вашите лични данни, касаещи Вас, се обработват, и, ако това е налице,
                        да поискате достъп до личните данни.
                    </li>
                    <li>
                        Редакция на своите лични данни.
                        <br />
                        Имате правото да получите копие от личните данни, които преминават през обработка. Можем да поискаме допълнителна информация от
                        вас за идентифициране или за допълнителни копия, които сте поискали.
                        <br />
                        Имате право да получите от нас поправка на неточни лични данни, свързани с вас. В зависимост от целите на обработването имате
                        право да допълните непълни лични данни, включително и чрез предоставяне на допълнителна декларация.
                    </li>
                    <li>
                        Изтриване на своите лични данни (право да бъдете забравени).
                        <br />
                        При определени обстоятелства е възможно да имате правото да поискате от нас да изтрием вашите личните данни и ние сме длъжни да изтрием съответните лични данни. В такъв случай вече няма да можем да ви предоставяме нашите услуги.
                    </li>
                    <li>
                        Право на ограничаване на обработката.
                        <br />
                        Имате право да поискате от нас ограничаване в обработването на вашите лични данни. В този случай съответните данни ще бъдат
                        маркирани и ще могат да се обработват от нас само за определени цели.
                    </li>
                    <li>
                        Право на възражение и права, свързани с автоматизираното взимане на решения.
                        <br />
                        При определени обстоятелства е възможно да възразите спрямо обработването на вашите лични данни, включително и спрямо
                        профилирането, извършвано от нас, а ние сме задължени повече да не обработваме вашите лични данни. В допълнение, ако вашите
                        лични данни се обработват предвид наш законен интерес, вие имате правото да възразите по всяко време спрямо обработката на
                        лични данни, касаещи вас за съответната цел. Освен това, при определени обстоятелства, в случаите на автоматизирано взимане
                        на индивидуални решения, вие имате правото да се възползвате от човешка намеса. ЦНТС не прилага механизъм за автоматизирано
                        взимане на решения.
                    </li>
                    <li>
                        Право на преносимост на данните.
                        <br />
                        Имате правото да получите своите лични данни, които сте ни предоставили, в структуриран често използван формат, който може да
                        се разчете от машина (напр. в дигитален формат). Възможно е и да имате правото да поискате прехвърляне на тези данни към друго лице,
                        без ние да възпрепятстваме това, ако такова прехвърляне е технически изпълнимо.
                    </li>
                    <li>
                        Право на оттегляне на съгласие.
                        <br />
                        Когато обработването на вашите лични данни се основава на ваше съгласие, можете да оттеглите това съгласие по всяко време. Оттеглянето на
                        съгласието не засяга правото за обработване на данни, които сме получили чрез вашето съгласие, дадено преди оттеглянето.
                        <br />
                        Ако оттеглите съгласието си за обработване на вашите лични данни, е възможно да не сме в състояние да ви предоставим всички или част от
                        услугите, които завявате (напр. в случай на специална помощ, ако за целта ние трябва да обработваме чувствителни данни).
                    </li>
                    <li>
                        Право на жалба.
                        <br />
                        Ако смятате, че не сме спазили задълженията си за защита на данните, се надяваме, че Вие ще пожелаете да се обърнете за разрешаването
                        на този въпрос  към нас като изпратите своето писмено искане или оплакване на имейл: raykov@cnts.bg
                        <br />
                        Имате обаче и право да подадете жалба до Комисията за защита на лични данни.
                    </li>
                </ol>
                <h2>ДАННИ ЗА КОНТАКТ.</h2>
                <p>
                    По отношение на въпроси, свързани със защитата на данните, за да упражните правата си, или за да подадете оплакване, моля използвайте
                    една от следните форми за контакт:<br />
                    Имейл: raykov@cnts.bg<br />
                    Пощенски адрес: България, гр. София, ул. Дунав 44
                </p>
            </PolicyScreenStyle>
            <StyledButton style={{ margin: '20px 0', width: '100%' }} onClick={() => setMenuScreen(props.prevScreen)}>Затвори</StyledButton>
        </div>
    );
};

export default PolicyScreen;