import { useEffect, useRef } from 'react';

import styled from 'styled-components';
import { RATING_COLORS } from '../pages/topic/SliderPanel';

const BetterSliderStyle = styled.div`
    display: block;
    box-sizing: border-box;
    position: relative;
    transition: width 1s ease, height 1s ease, margin 1s ease, background 1s ease;
    display: inline-block;
    vertical-align: top;
    border-radius: 40px;

    width: 75px;
    margin: 28px 0 0 calc(50% - 75px / 2);
    height: calc(100% - 58px);

    &.shrink {
        width: 10px;
        //margin: 30px 20px 20px 40px;
        margin: 30px 0px 20px 40px;
        height: calc(100% - 85px);

        //background: linear-gradient(to top, #e63939, #ff4d40, #c36eff, #538cff, #53b7ff, #1ed931);
    }

    @media only screen and (max-width: 550px) {
        //margin: 28px auto 0 auto;
        //height: calc(100% - 38px);
    }

    .better-slider-track-0 {
        position: absolute;
        top: 0%;
        height: calc(50% + 75px / 2);
        width: 100%;

        border-radius: 40px;
        background-color: #3335;
        box-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.1) inset, 
                    1px 1.5px 4px rgba(0, 0, 0, 0.08) inset, 
                    0 -0.5px 1px rgba(255, 255, 255, 0.25) inset,
                    0 -0.5px 1px rgba(255, 255, 255, 0.3) inset;

        &.shrink {
            height: calc(50% + 10px / 2);
            box-shadow: none;
            background-color: transparent;
        }
    }

    .better-slider-track-1 {
        position: absolute;
        top: calc(50% - 75px / 2);
        height: calc(50% + 75px / 2);
        width: 100%;
        
        background-color: green;

        border-radius: 40px;
        background-color: #ccc9;
        box-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.1) inset, 
                    1px 1.5px 4px rgba(0, 0, 0, 0.08) inset, 
                    0 -0.5px 1px rgba(255, 255, 255, 0.25) inset,
                    0 -0.5px 1px rgba(255, 255, 255, 0.3) inset;

        &.shrink {
            top: calc(50% - 10px / 2);
            height: calc(50% + 10px / 2);

            background-color: #3335;
            /*box-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.1) inset, 
                        1px 1.5px 4px rgba(0, 0, 0, 0.08) inset, 
                        0 -0.5px 1px rgba(255, 255, 255, 0.25) inset,
                        0 -0.5px 1px rgba(255, 255, 255, 0.3) inset;*/
            box-shadow: none;
            background-color: transparent;
        }
    }

    .better-slider-thumb {
        background-color: blue;
        width: 75px;
        height: 75px;
        top: calc(50% - 75px / 2);
        position: absolute;

        background-color: #fff;
        border-radius: 50%;
        border: none;
        box-shadow: 0 2px 1px #fff inset, 0 -2px 1px #666 inset, 0 0 10px #666;
        touch-action: none;
        user-select: none;

        will-change: transform;
        transform: translate3d(0,0,0);
        transition: width 1s ease, height 1s ease;

        &.shrink {
            width: 10px;
            height: 10px;
            box-shadow: none;
        }
    }

    .rating-mark-top {
        top: -20px;
        left: -4px;
        color: white;
        position: absolute;
        transition: opacity 1s ease 1.5s;
    }

    .rating-mark-bottom {
        bottom: -20px;
        left: -4px;
        color: white;
        position: absolute;
        transition: opacity 1s ease 1.5s;
    }
`;

const VoteValue = styled.div`
    position: absolute;
    font-size: 60px;
    font-weight: 900;
    color: white;
    text-shadow: 0 0 10px #666;
    background-color: none;
    border-radius: 30px;
    padding: 20px;
    text-align: center;

    margin-left: -38px;
    margin-top: -78px;
    width: 150px;
    
    font-size: 38px;
    font-weight: normal;

    pointer-events: none;
`;

interface BetterSliderProps {
    sliderTouched?: () => void;
    sliderMoved?: (sliderValue: number) => void;
    sliderReleased?: (sliderValue: number) => void;
    shrink: boolean;
    referenceRating: number | null;
    referenceColor: string | null;
}

const BetterSlider: React.FC<BetterSliderProps> = props => {

    const containerRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);
    const track1Ref = useRef<HTMLDivElement>(null);
    const track2Ref = useRef<HTMLDivElement>(null);

    const initialClickOffset = useRef<number>(0);
    const voteValueRef = useRef<HTMLDivElement>(null);

    const sliderValueRef = useRef<number | null>(null);

    const lastUpdateRef = useRef<number | null>(null);

    const propsRef = useRef<BetterSliderProps>(props);

    useEffect(() => { propsRef.current = props; }, [props]);

    useEffect(() => {
        if (props.shrink) {
            if (!thumbRef.current) return;
            thumbRef.current.style.top = `calc(${1 - (sliderValueRef.current ?? 0.5)} * (100% - 10px))`;
        }
    }, [props.shrink]);

    const recalculatePositions = (clientY: number) => {
        if (!containerRef.current) return;
        if (!thumbRef.current) return;
        if (!track1Ref.current) return;
        if (!track2Ref.current) return;

        if (lastUpdateRef.current !== null) {
            const interval = Date.now() - lastUpdateRef.current;
            if (interval < 30)
                return;
        }
        lastUpdateRef.current = Date.now();

        const rect = containerRef.current.getBoundingClientRect();
        let newPosition = clientY - rect.top - (thumbRef.current.offsetWidth / 2) - initialClickOffset.current;

        newPosition = Math.max(newPosition, 0);
        newPosition = Math.min(newPosition, containerRef.current.offsetHeight - thumbRef.current.offsetHeight);

        const sliderValue = 1 - newPosition / (containerRef.current.offsetHeight - thumbRef.current.offsetHeight);
        sliderValueRef.current = sliderValue;

        thumbRef.current.style.top = `calc(${1 - sliderValueRef.current} * (100% - ${thumbRef.current.offsetHeight}px))`;
        //thumbRef.current.style.top = `calc(${(1 - sliderValue) * 100}% - ${(1 - sliderValue) * thumbRef.current.offsetHeight}px)`;

        const track1height = `calc(${(1 - sliderValue) * 100}% + ${sliderValue * thumbRef.current.offsetHeight}px)`;
        const track2top = `calc(${(1 - sliderValue) * 100}% + ${(sliderValue - 1) * thumbRef.current.offsetHeight}px)`;
        const track2height = `calc(${thumbRef.current.offsetHeight}px + ${(sliderValue) * 100}% - ${(sliderValue) * thumbRef.current.offsetHeight}px)`;

        track1Ref.current.style.height = track1height;
        track2Ref.current.style.height = track2height;
        track2Ref.current.style.top = track2top;

        if (propsRef.current.sliderMoved)
            propsRef.current.sliderMoved(Math.round(1 + sliderValue * 99));

        if (!voteValueRef.current)
            return;
        voteValueRef.current.textContent = getSliderValue(Math.round(1 + sliderValue * 99)).toString();
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();

        if (props.shrink)
            return;

        if (propsRef.current.sliderTouched)
            propsRef.current.sliderTouched();

        if (!thumbRef.current) return;
        if (!containerRef.current) return;

        // this allows the thumb to be dragged on every point
        const rect = containerRef.current.getBoundingClientRect();
        initialClickOffset.current = e.clientY - rect.top - (thumbRef.current.offsetTop + thumbRef.current.clientHeight / 2);

        const handleMouseMove = (e: MouseEvent) => {
            e.preventDefault();
            recalculatePositions(e.clientY);
        };

        const handleMouseUp = () => {

            if (propsRef.current.sliderReleased)
                propsRef.current.sliderReleased(Math.round(1 + (sliderValueRef.current ?? 0) * 99));

            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove, { passive: false });
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        e.preventDefault();

        if (props.shrink)
            return;

        if (propsRef.current.sliderTouched)
            propsRef.current.sliderTouched();

        if (!thumbRef.current) return;
        if (!containerRef.current) return;

        // this allows the thumb to be dragged on every point
        const rect = containerRef.current.getBoundingClientRect();
        initialClickOffset.current = e.touches[0].clientY - rect.top - (thumbRef.current.offsetTop + thumbRef.current.clientHeight / 2);

        const handleTouchMove = (e: TouchEvent) => {
            recalculatePositions(e.touches[0].clientY);
            e.preventDefault();
        };

        const handleTouchEnd = () => {

            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);

            if (propsRef.current.sliderReleased)
                propsRef.current.sliderReleased(Math.round(1 + (sliderValueRef.current ?? 0) * 99));
        };

        window.addEventListener('touchmove', handleTouchMove, { passive: false });
        window.addEventListener('touchend', handleTouchEnd);
    };

    const getSliderValue = (sliderValue: number) => {
        const sliderValueTemp = sliderValue ?? 50;
        if (sliderValueTemp % 10 === 0 && sliderValueTemp !== 100) {
            return `${sliderValueTemp / 10}.0`;
        } else {
            return sliderValueTemp / 10;
        }
    };

    return (
        <BetterSliderStyle ref={containerRef} className={props.shrink ? ' shrink' : ''}>
            <div style={{
                background: `linear-gradient(to top, ${RATING_COLORS[0]}, ${RATING_COLORS[1]}, ${RATING_COLORS[2]}, ${RATING_COLORS[3]}, ${RATING_COLORS[4]}, ${RATING_COLORS[5]})`,
                height: '100%',
                borderRadius: '40px',
                opacity: !props.shrink ? 0 : 1,
                transition: 'opacity 1s ease'
            }} />
            <div className={'better-slider-track-0' + (props.shrink ? ' shrink' : '')} ref={track1Ref} />
            <div className={'better-slider-track-1' + (props.shrink ? ' shrink' : '')} ref={track2Ref} />
            <div className={'better-slider-thumb' + (props.shrink ? ' shrink' : '')} ref={thumbRef} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
                {props.shrink ? null : <VoteValue ref={voteValueRef}></VoteValue>}
                <div style={{
                    marginLeft: '-34px', 
                    marginTop: '-5px', 
                    color: 'white', 
                    opacity: props.shrink ? 1 : 0,
                    transition: 'opacity 1s ease 1s'
                }}>
                    Вие
                </div>
            </div>
            <div className={'better-slider-thumb shrink'} style={{
                top: `calc(${1 - ((props.referenceRating ?? 50) - 1) / 99} * (100% - 10px))`,
                opacity: props.referenceRating && props.shrink ? 1 : 0,
                zIndex: 1,
                transition: 'top 0.65s ease, opacity 0.65s ease',
            }} />
            <div className='rating-mark-top' style={{ opacity: props.shrink ? 1 : 0 }}>10</div>
            <div className='rating-mark-bottom' style={{ opacity: props.shrink ? 1 : 0 }}>0.1</div>
        </BetterSliderStyle>
    );
};

export default BetterSlider;