
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

import styled from 'styled-components';
import { AppContext } from "../../App";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import SmileyFaceDots from "./SmileyFaceDots";
import BetterSlider from "../../components/BetterSlider";
import { getSourceParamsValues, hexToRgb, interpolateColor, ratingColorGradient } from "../../util/util";
import { useParams } from "react-router-dom";
import VoteResult from "./results/VoteResult";
import OptionsButton from "../../components/OptionsButton";

const PartnerLogo = styled.div`
    position: absolute;
    text-align: right;
    right: 40px;

    p {
        left: 5px;
        bottom: 105px;
        width: 150px;
        font-size: 16px;
        color: black;
        margin: 0;
        color: #fff;
        opacity: 0.8;
    }

    img {
        left: 15px;
        bottom: 60px;
        width: 90px; 
        opacity: 0.5;
    }
`;

const SmileyFaceContainer = styled.div`
    position: absolute;
    left: 2px;
    margin-top: -10px;
    width: 215px;
    height: 215px;

    @media only screen and (max-width: 550px) {
        width: calc(100vw / 2 - 60px);
        height: calc(100vw / 2 - 60px);
    }
`;



export type SliderTouchedFunction = () => void;
export type SliderMovedFunction = (sliderValue: number) => void;
export type SubmitRatingFunction = (voteValue: number) => void;

export const RATING_COLORS = ['#e63939', '#ff4d40', '#c36eff', '#538cff', '#53b7ff', '#1ed931'];
//export const RATING_COLORS = ['#E21E28', '#614496', '#1E4389', '#1AA7C2', '#81BC7A', '#308F4B'];

enum SliderAnimationState {
    Initial = 'initial',
    SliderTransform = 'slider-transform',
    ResultsFadeIn = 'results-fade-in'
}

enum VoteState {
    Initial = 'initial',
    SliderTouched = 'slider-touched',
    SliderMoved = 'slider-moved',
    SliderReleased = 'slider-released'
}

interface SliderPanelProps {
    voteValue: number | null;
    submitValue: (voteValue: number, voteId: number) => void;
    onAnimationComplete: () => void;
}

interface SliderPanelState {
    voteState: VoteState;
    sliderAnimationState: SliderAnimationState;
    voteOpenTime: number | null;
    sliderTouchTime: number | null;
    referenceRating: number | null;
    referenceColor: string | null;
}

const SliderPanel: React.FC<SliderPanelProps> = (props) => {

    const { apiUrl, topic, setGlassPanelColor, setGlassPanelBackdropFilter } = useContext(AppContext);

    const { topicHash }: { topicHash: string } = useParams();

    const updateSmileyRef = useRef<(sliderValue: number) => void>();

    const propsRef = useRef<SliderPanelProps>(props);
    useEffect(() => { propsRef.current = props; }, [props]);

    const [styles, api] = useSpring(() => ({
        color: '#6664',
        onChange: (values) => {
            setGlassPanelColor(values.value.color);
        }
    }));

    const [state, setState] = useState<SliderPanelState>({
        voteState: VoteState.Initial,
        sliderAnimationState: SliderAnimationState.Initial,
        voteOpenTime: null,
        sliderTouchTime: null,
        referenceRating: null,
        referenceColor: null
    });

    const stateRef = useRef<SliderPanelState>(state);
    useEffect(() => { stateRef.current = state; }, [state]);

    useEffect(() => {
        if (topic) {
            setState({ ...state, voteOpenTime: Date.now() });
        }
    }, [topic]);

    const sliderTouched: SliderTouchedFunction = () => {
        //console.log('Slider touched', Date.now(), 'Pre-vote time: ', Date.now() - (state.voteOpenTime ?? 0));

        api.start({
            to: { color: '#fff8' },
            config: { duration: 2000 },
        });

        setGlassPanelBackdropFilter(40, 0, 1);

        if (state.voteState === VoteState.Initial) {
            setState({
                ...state,
                voteState: VoteState.SliderTouched,
                sliderTouchTime: Date.now()
            });
        }
    };

    const sliderMoved: SliderMovedFunction = (sliderValue: number) => {
        api.stop();

        if (updateSmileyRef.current)
            updateSmileyRef.current(sliderValue);

        let bgColor = '#808080';

        if (sliderValue) {
            bgColor = ratingColorGradient(RATING_COLORS, sliderValue);
        }

        setGlassPanelColor(bgColor + '4D');

        if (state.voteState !== VoteState.SliderMoved)
            setState({ ...state, voteState: VoteState.SliderMoved });
    }

    const sliderReleased: SubmitRatingFunction = async (voteValue) => {
        //console.log('Slider released', Date.now(), 'Time in voting: ', Date.now() - (state.sliderTouchTime ?? 0));

        setState({
            ...state,
            voteState: VoteState.SliderReleased,
            sliderAnimationState: SliderAnimationState.SliderTransform,
        });

        const timeBeforeVoting = state.sliderTouchTime === null || state.voteOpenTime === null ? 0
            : state.sliderTouchTime - state.voteOpenTime;
        const timeInVoting = state.sliderTouchTime === null ? 0 : (Date.now() - state.sliderTouchTime);

        const res = await axios.post(`${apiUrl}/vote/${topic?.id}`, {
            topicName: topicHash,
            origin: document.referrer,
            vote: voteValue,
            timeBeforeVoting,
            timeInVoting,
            sources: getSourceParamsValues()
        });

        props.submitValue(voteValue, parseInt(res.data.voteId, 10));

        setTimeout(() => {
            setState({ ...stateRef.current, sliderAnimationState: SliderAnimationState.ResultsFadeIn });
        }, 1000);
    };

    const setReferenceRating = (referenceRating: number | null, referenceColor: string | null) => {
        setState({...state, referenceRating, referenceColor });
    };

    return (
        <>
            {!(state.voteState === VoteState.Initial || 
                state.voteState === VoteState.SliderTouched ||
                state.voteState === VoteState.SliderMoved    
            ) ? null :
                !topic?.entityLogo ? null :
                    <PartnerLogo>
                        <p>казвате на</p>
                        <img src={topic?.entityLogo} />
                    </PartnerLogo>
            }
            {state.voteState !== VoteState.SliderMoved ? null :
                <SmileyFaceContainer>
                    <SmileyFaceDots
                        updateSmileyRef={(updateSmiley: (sliderValue: number) => void) => updateSmileyRef.current = updateSmiley}
                    />
                </SmileyFaceContainer>
            }
            <BetterSlider
                sliderTouched={sliderTouched}
                sliderMoved={sliderMoved}
                sliderReleased={sliderReleased}
                shrink={props.voteValue ? true : false}
                referenceRating={state.referenceRating}
                referenceColor={state.referenceColor}
            />
            {state.voteState !== VoteState.Initial ? null :
                <OptionsButton absolute/>
            }
            {state.sliderAnimationState !== SliderAnimationState.ResultsFadeIn ? null :
                <VoteResult 
                    voteValue={props.voteValue ?? 50} 
                    onAnimationComplete={propsRef.current.onAnimationComplete}
                    setReferenceRating={setReferenceRating}
                />
            }
        </>
    );
}

export default SliderPanel;