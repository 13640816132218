
import styled from 'styled-components';

const RotateMeStyle = styled.div`
    background: #eee;
    z-index: 999;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    vertical-align: top;
    padding-top: calc(50vh - 100px);

    img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        vertical-align: top;
    }

    p {
        color: #464646;
        font-size: 36px;
        vertical-align: top;
    }

    @media (max-height: 561px) and (orientation: landscape) {
        display: block;
    }
`;

const RotateMe: React.FC = () => {
    return (
        <RotateMeStyle>
            <p>
                <img src='/rotateme.svg' />
                <span style={{ display: 'inline-block', paddingTop: '40px' }}>Завъртете устройството си</span>
            </p>
        </RotateMeStyle>
    );
};

export default RotateMe;