import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AppContext, GenderData } from '../../../App';
import axios from 'axios';

import styled from 'styled-components';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { animated, useSpring } from 'react-spring';
import { ratingColorGradient } from '../../../util/util';
import { RATING_COLORS } from '../SliderPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons/faPeopleGroup';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faVenusMars } from '@fortawesome/free-solid-svg-icons/faVenusMars';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion';
import { faCakeCandles } from '@fortawesome/free-solid-svg-icons/faCakeCandles';
import { faMars } from '@fortawesome/free-solid-svg-icons/faMars';
import { faVenus } from '@fortawesome/free-solid-svg-icons/faVenus';
import StyledDropDown from '../../../components/StyledDropdown';
import { faMedal } from '@fortawesome/free-solid-svg-icons/faMedal';
import TypingText from '../../../components/TypingTextV2';
import ResultSlide from './ResultSlide';

/*
    Vote Result logic (from the old voter app):
    - total_votes_average is calculated = vt.tvs / vt.tvc
    - if user voted > 5.0 and TVA is also >= 5.0 then you get agreement message
    - if user voted < 5.0 and TVA is >= 5.0 then you get disagreement
    - etc. (total 4 scenarios)
    - user is male and vote >= 5.0 and vt.tmhv > tmlv then display "most men agree with you"
    - user is female and vote >= 5.0 and tfhv > tflv then display "most women agree with you"
    - etc. (4 agreements and 4 disagreements)
    - user is ag0 and vote is >= 5.0 and ag0tvs / ag0tvc is > 5.0 then agreement message
    - etc. (many possible cases)
    - user is ag0 and male and vote is >= 5.0 and ag0mhvc > ag1lvc

    Vote Result panel should display results for:
    - Total
    - By gender
    - By age
    - By age and gender

*/

type AgeGroup = 'ag0' | 'ag1' | 'ag2' | 'ag3' | 'ag4' | 'ag5' | 'ag6' | 'ag7';
type Gender = 'male' | 'female';
type CountType = 'vote-count' | 'vote-sum' | 'high-votes' | 'low-votes';

const getResult = (topicVoteData: any, ageGroup: AgeGroup | null, gender: Gender | null, countType: CountType) => {
    if (!topicVoteData)
        return null;

    let key = '';

    key += ageGroup ?? 't';

    if (gender && gender === 'male')
        key += 'm';
    else if (gender && gender === 'female')
        key += 'f';

    if (ageGroup && !gender)
        key += 't';

    if (countType === 'vote-count')
        key += 'vc';
    else if (countType === 'vote-sum')
        key += 'vs';
    else if (countType === 'high-votes')
        key += 'hv';
    else if (countType === 'low-votes')
        key += 'lv';

    if (ageGroup && (countType === 'high-votes' || countType === 'low-votes')) {
        key += 'c';
    }

    return topicVoteData[key];
};

const getAgeGroup = (birthYear: number): AgeGroup | null => {
    const voterAge: number = new Date().getFullYear() - birthYear;

    if (voterAge > 0 && voterAge < 12) return 'ag0';
    if (voterAge > 11 && voterAge < 18) return 'ag1';
    if (voterAge > 17 && voterAge < 25) return 'ag2';
    if (voterAge > 24 && voterAge < 35) return 'ag3';
    if (voterAge > 34 && voterAge < 45) return 'ag4';
    if (voterAge > 44 && voterAge < 55) return 'ag5';
    if (voterAge > 54 && voterAge < 65) return 'ag6';
    if (voterAge > 64) return 'ag7';

    return null;
};

const VoteResultStyle = styled(animated.div)`
    width: calc(100% - 50px);
    height: 100%;
    display: inline-block;
    overflow: hidden;

    .slick-slider {
        height: calc(100% - 25px);
    }

    .slick-list {
        overflow: visible;
        width: 70%;
    }

    .slick-slide,
    .slick-list,
    .slick-track,
    .slick-slide > div {
        height: 100%;
    }

    ul.slick-dots {
        padding-right: 50px;
    }

    .slick-dots li button::before {
        font-size: 10px;
    }
`;

interface VoteResultProps {
    voteValue: number;
    onAnimationComplete: () => void;
    setReferenceRating: (referenceRating: number | null, referenceColor: string | null) => void;
}

export interface GroupResult {
    rating: number | null;
    voteCount: number | null;
    color: string;
    voteHeight: 'high' | 'low' | null;
}

interface VoteResultState {
    currentSlide: number;
    fadeInComplete: boolean;
}

const VoteResult: React.FC<VoteResultProps> = (props) => {

    const { apiUrl, topic, profile, setProfile } = useContext(AppContext);

    const [state, setState] = useState<VoteResultState>(() => ({
        currentSlide: 0,
        fadeInComplete: false,
    }))

    const [resultContainerStyle, resultContainerApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duation: 600 },
        onRest: () => setState({ ...state, fadeInComplete: true })
    }));

    const calculateResults = () => {

        const groupResults: { [groupString: string]: GroupResult } = {
            majority: { rating: null, color: '#00000055', voteHeight: null, voteCount: null },
            gender: { rating: null, color: '#00000055', voteHeight: null, voteCount: null },
            age: { rating: null, color: '#00000055', voteHeight: null, voteCount: null },
            ageGender: { rating: null, color: '#00000055', voteHeight: null, voteCount: null },
        };

        const totalVoteCount = getResult(topic?.vt, null, null, 'vote-count');
        const totalVoteSum = getResult(topic?.vt, null, null, 'vote-sum');

        groupResults.majority.rating = Math.round(totalVoteSum / totalVoteCount);
        groupResults.majority.color = ratingColorGradient(RATING_COLORS, groupResults.majority.rating);
        groupResults.majority.voteHeight = groupResults.majority.rating >= 50 ? 'high' : 'low';
        groupResults.majority.voteCount = totalVoteCount;

        if (profile.gender && profile.gender !== 'other') {
            const highVotes = getResult(topic?.vt, null, profile.gender, 'high-votes');
            const lowVotes = getResult(topic?.vt, null, profile.gender, 'low-votes');

            if (highVotes + lowVotes > 0) {
                groupResults.gender.rating = Math.round((80 * highVotes + 20 * lowVotes) / (highVotes + lowVotes));
                groupResults.gender.color = ratingColorGradient(RATING_COLORS, groupResults.gender.rating);
                groupResults.gender.voteHeight = highVotes > lowVotes ? 'high' : 'low';
            } else {
                groupResults.gender.rating = props.voteValue;
                groupResults.gender.color = ratingColorGradient(RATING_COLORS, groupResults.gender.rating);
                groupResults.gender.voteHeight = props.voteValue > 50 ? 'high' : 'low';
            }

            groupResults.gender.voteCount = highVotes + lowVotes;
        }

        if (profile.birthYear) {
            const ageGroup: AgeGroup | null = getAgeGroup(profile.birthYear);

            const totalVoteSum = getResult(topic?.vt, ageGroup, null, 'vote-sum');
            const totalVoteCount = getResult(topic?.vt, ageGroup, null, 'vote-count');

            if (totalVoteCount > 0) {
                groupResults.age.rating = Math.round(totalVoteSum / totalVoteCount);
                groupResults.age.color = ratingColorGradient(RATING_COLORS, groupResults.age.rating);
                groupResults.age.voteHeight = groupResults.age.rating >= 50 ? 'high' : 'low';
            } else {
                groupResults.age.rating = props.voteValue;
                groupResults.age.color = ratingColorGradient(RATING_COLORS, groupResults.age.rating);
                groupResults.age.voteHeight = props.voteValue > 50 ? 'high' : 'low';
            }

            groupResults.age.voteCount = totalVoteCount;
        }

        if (profile.gender && profile.birthYear && profile.gender !== 'other') {
            const ageGroup: AgeGroup | null = getAgeGroup(profile.birthYear);

            const highVotes = getResult(topic?.vt, ageGroup, profile.gender, 'high-votes');
            const lowVotes = getResult(topic?.vt, ageGroup, profile.gender, 'low-votes');

            if (highVotes + lowVotes > 0) {
                groupResults.ageGender.rating = Math.round((80 * highVotes + 20 * lowVotes) / (highVotes + lowVotes));
                groupResults.ageGender.color = ratingColorGradient(RATING_COLORS, groupResults.ageGender.rating);
                groupResults.ageGender.voteHeight = highVotes > lowVotes ? 'high' : 'low';
            } else {
                groupResults.ageGender.rating = props.voteValue;
                groupResults.ageGender.color = ratingColorGradient(RATING_COLORS, groupResults.ageGender.rating);
                groupResults.ageGender.voteHeight = props.voteValue > 50 ? 'high' : 'low';
            }

            groupResults.ageGender.voteCount = highVotes + lowVotes;
        }

        return groupResults;
    };

    const groupResult: { [groupString: string]: GroupResult } = useMemo(() => calculateResults(), [profile.gender, profile.birthYear]);

    useEffect(() => {
        props.setReferenceRating(groupResult.gender.rating, groupResult.gender.color);
    }, [profile.gender]);

    useEffect(() => {
        props.setReferenceRating(groupResult.age.rating, groupResult.age.color);
    }, [profile.birthYear]);

    const submitGender = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const gender: GenderData = e.target.value as GenderData;
        await axios.put(`${apiUrl}/user`, { topicId: topic?.id, gender });
        setProfile({ ...profile, gender });
    };

    const submitBirthYear = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const birthYear: number = parseInt(e.target.value, 10);
        await axios.put(`${apiUrl}/user`, { topicId: topic?.id, birthYear });
        setProfile({ ...profile, birthYear });
    }

    const getTotalResultMessage = () => {
        if (groupResult.majority.rating === null)
            return null;

        if (groupResult.gender.voteCount === 0) {
            return (
                <>
                    <span style={{ fontSize: '56px' }}>
                        <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faPeopleGroup} /></span>
                        <FontAwesomeIcon icon={faMedal} />
                    </span>
                    <p>
                        Вие сте <b>първият</b>, който отговаря на този въпрос!
                    </p>
                </>
            );
        }

        const averageVote: number = groupResult.majority.rating;
        const userVote = props.voteValue;

        const agreement: boolean = (userVote >= 50 && averageVote >= 50) || (userVote < 50 && averageVote < 50);
        const agreementIcon = agreement ? faThumbsUp : faThumbsDown;

        return (
            <>
                <span style={{ fontSize: '56px' }}>
                    <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faPeopleGroup} /></span>
                    <FontAwesomeIcon icon={agreementIcon} />
                </span>
                <p> Повечето хора <b>{!agreement ? 'не ' : ''}са съгласни </b> с Вас.</p>
            </>
        );
    };

    const getGenderResultMessage = () => {
        if (profile.gender === 'other')
            return null;

        if (groupResult.gender.voteCount === 0) {
            const genderIcon = profile.gender === 'male' ? faMars : faVenus;
            const genderStr = profile.gender === 'male' ? 'първият мъж' : 'първата жена';
            return (
                <>
                    <span style={{ fontSize: '56px' }}>
                        <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={genderIcon} /></span>
                        <FontAwesomeIcon icon={faMedal} />
                    </span>
                    <p>
                        Вие сте <b>{genderStr}</b>, {profile.gender === 'male' ? 'който' : 'която'} отговаря на този въпрос!
                    </p>
                </>
            );
        }

        if (groupResult.gender.voteHeight === null)
            return null;

        const averageVoteHigh: boolean = groupResult.gender.voteHeight === 'high';
        const userVote = props.voteValue;

        const agreement: boolean = (userVote >= 50 && averageVoteHigh) || (userVote < 50 && !averageVoteHigh);

        const genderIcon = profile.gender === 'male' ? faMars : faVenus;
        const genderStr = profile.gender === 'male' ? 'мъже' : 'жени';
        const agreementIcon = agreement ? faThumbsUp : faThumbsDown;

        return (
            <>
                <span style={{ fontSize: '56px' }}>
                    <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={genderIcon} /></span>
                    <FontAwesomeIcon icon={agreementIcon} />
                </span>
                <p>Повечето {genderStr} <b>{!agreement ? 'не ' : ''}са съгласни</b> с Вас.</p>
            </>
        );
    };

    const getAgeResultMessage = () => {
        if (profile.birthYear === null)
            return null;

        const ageGroup: AgeGroup | null = getAgeGroup(profile.birthYear);

        if (ageGroup === null)
            return null;

        if (groupResult.age.voteCount === 0) {
            return (
                <>
                    <span style={{ fontSize: '56px' }}>
                        <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faCakeCandles} /></span>
                        <FontAwesomeIcon icon={faMedal} />
                    </span>
                    <p>
                        Вие сте <b>първият</b> на Вашата възраст, който отговаря на този въпрос!
                    </p>
                </>
            );
        }

        if (groupResult.age.rating === null)
            return null;

        const averageVote: number = groupResult.age.rating;
        const userVote = props.voteValue;

        const agreement: boolean = (userVote >= 50 && averageVote >= 50) || (userVote < 50 && averageVote < 50);

        const agreementIcon = agreement ? faThumbsUp : faThumbsDown;

        return (
            <>
                <span style={{ fontSize: '56px' }}>
                    <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faCakeCandles} /></span>
                    <FontAwesomeIcon icon={agreementIcon} />
                </span>
                <p>Повечето хора на Вашата възраст <b>{!agreement ? 'не ' : ''}са съгласни</b> с Вас.</p>
            </>
        );
    };

    const getAgeAndGenderResultMessage = () => {
        if (profile.birthYear === null)
            return null;

        const ageGroup: AgeGroup | null = getAgeGroup(profile.birthYear);

        if (ageGroup === null)
            return null;

        if (profile.gender === 'other')
            return null;

        if (groupResult.ageGender.voteCount === 0) {
            const genderIcon = profile.gender === 'male' ? faMars : faVenus;
            const genderStr = profile.gender === 'male' ? 'първият мъж' : 'първата жена';
            return (
                <>
                    <span style={{ fontSize: '56px' }}>
                        <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={genderIcon} /></span>
                        <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faCakeCandles} /></span>
                        <FontAwesomeIcon icon={faMedal} />
                    </span>
                    <p>
                        Вие сте <b>{genderStr}</b> на Вашата възраст, {profile.gender === 'male' ? 'който' : 'която'} отговаря на този въпрос!
                    </p>
                </>
            );
        }

        if (groupResult.ageGender.voteHeight === null)
            return null;

        const averageVoteHigh: boolean = groupResult.ageGender.voteHeight === 'high';
        const userVote = props.voteValue;

        const agreement: boolean = (userVote >= 50 && averageVoteHigh) || (userVote < 50 && !averageVoteHigh);

        const genderIcon = profile.gender === 'male' ? faMars : faVenus;
        const genderStr = profile.gender === 'male' ? 'мъже' : 'жени';
        const agreementIcon = agreement ? faThumbsUp : faThumbsDown;

        return (
            <>
                <span style={{ fontSize: '56px', marginTop: '20px', display: 'block' }}>
                    <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={genderIcon} /></span>
                    <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faCakeCandles} /></span>
                    <FontAwesomeIcon icon={agreementIcon} />
                </span>
                <p>Повечето {genderStr} на Вашата възраст <b>{!agreement ? 'не ' : ''}са съгласни</b> с Вас.</p>
            </>
        );
    };

    useEffect(() => {
        props.setReferenceRating(groupResult.majority.rating ?? 0.5, groupResult.majority.color ?? '#00000055');
    }, []);

    const sliderRef = useRef<Slider>(null);

    const slideClicked = (slideIndex: number) => {
        if (slideIndex === state.currentSlide + 1)
            sliderRef.current?.slickNext();
    };

    return (
        <VoteResultStyle style={resultContainerStyle}>
            <Slider
                dots={true}
                arrows={false}
                infinite={false}
                speed={500}
                slidesToScroll={1}
                slide='article'
                ref={sliderRef}
                beforeChange={(oldIndex, newIndex) => {

                    if (newIndex === 0) {
                        props.setReferenceRating(groupResult.majority.rating, groupResult.majority.color);
                    } else if (newIndex === 1) {
                        props.setReferenceRating(groupResult.gender.rating, groupResult.gender.color);
                    } else if (newIndex === 2) {
                        props.setReferenceRating(groupResult.age.rating, groupResult.age.color);
                    } else if (newIndex === 3) {
                        props.setReferenceRating(groupResult.ageGender.rating, groupResult.ageGender.color);
                    }
                }}
                afterChange={(index) => {
                    setState({ ...state, currentSlide: index })
                }}
            >
                <ResultSlide index={0} groupResult={groupResult.majority} currentSlide={state.currentSlide} slideClicked={() => slideClicked(0)}>
                    <TypingText active={state.fadeInComplete} delay={10} onComplete={props.onAnimationComplete}>
                        {getTotalResultMessage()}
                    </TypingText>
                </ResultSlide>
                <ResultSlide index={1} groupResult={groupResult.gender} currentSlide={state.currentSlide} slideClicked={() => slideClicked(1)}>
                    {profile.gender ? getGenderResultMessage()
                        : <>
                            <span style={{ fontSize: '42px' }}>
                                <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faVenusMars} /></span>
                                <FontAwesomeIcon icon={faCircleQuestion} />
                            </span>
                            <p style={{ fontSize: '16px', marginBottom: '10px' }}>Нека ти покажа дали хората от твоя пол са съгласни с теб!</p>
                            <StyledDropDown style={{ fontSize: '16px', padding: '17px 20px' }} onChange={submitGender} value={'Вашият пол'}>
                                <option disabled>Вашият пол</option>
                                <option value="male">Мъж</option>
                                <option value="female">Жена</option>
                                <option value="other">Друго</option>
                            </StyledDropDown>
                            <p style={{ fontSize: '16px', marginTop: '10px' }}>Вашият пол няма да бъде споделен с никого.</p>
                        </>}
                </ResultSlide>
                <ResultSlide index={2} groupResult={groupResult.age} currentSlide={state.currentSlide} slideClicked={() => slideClicked(2)}>
                    {profile.birthYear ? getAgeResultMessage() : <>
                        <span style={{ fontSize: '42px' }}>
                            <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faCakeCandles} /></span>
                            <FontAwesomeIcon icon={faCircleQuestion} />
                        </span>
                        <p style={{ fontSize: '16px', marginBottom: '10px' }}>Нека ти покажа дали хората на твоята възраст са съгласни с теб!</p>
                        <StyledDropDown style={{ fontSize: '16px', padding: '17px 20px' }} onChange={submitBirthYear} value={'Вашата година на раждане'}>
                            <option disabled>Вашата година на раждане</option>
                            {Array.from(Array(77).keys()).map(n => 2016 - n).map(year =>
                                <option key={year} value={year}>{year}</option>
                            )}
                        </StyledDropDown>
                        <p style={{ fontSize: '16px', marginTop: '10px' }}>Вашата възраст няма да бъде споделена с никого.</p>
                    </>}
                </ResultSlide>
                <ResultSlide index={3} groupResult={groupResult.ageGender} currentSlide={state.currentSlide} slideClicked={() => slideClicked(3)}>
                    {(profile.gender && profile.birthYear) ? getAgeAndGenderResultMessage() : <>
                        <span style={{ fontSize: '42px' }}>
                            <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faVenusMars} /></span>
                            <span style={{ marginRight: '15px' }}><FontAwesomeIcon icon={faCakeCandles} /></span>
                            <FontAwesomeIcon icon={faCircleQuestion} />
                        </span>
                        <p style={{ fontSize: '16px' }}>
                            Изберете възраст и пол, за да ви покажем дали хората
                            от Вашия пол и на Вашата възраст са съгласни с Вас!
                        </p>
                    </>}
                </ResultSlide>
            </Slider>
        </VoteResultStyle>
    );
};

export default VoteResult;