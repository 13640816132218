import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { AppContext } from '../../App';
import MultiSelect from './answers/MultiSelect';

import { Question } from 'holler-voterapp-model';
import SingleSelect from './answers/SingleSelect';
import OpenStructure from './answers/OpenStructure';
import TypingText from '../../components/TypingText';
import { animated, useSpring } from 'react-spring';
import StyledContentPanel from '../../components/StyledContentPanel';

export type VoteEndFunction = (voteEnd: boolean) => void;

interface QuestionAndAnswerProps {
    voteValue: number | null;
    voteId: number | null;
    setVoteEnd: VoteEndFunction;
    firstQuestionAnswered: () => void;
}

enum QnAAnimationState {
    ContentPanelFadeIn = 'content-panel-fade-in',
    TypeTitleText = 'type-title-text',
    AnswersFadeIn = 'answers-fade-in',
    QuestionFadeOut = 'question-fade-out'
}

interface QuestionAndAnswerState {
    qnaAnimationState: QnAAnimationState;
    questionId: number | undefined;
    questionTime: number;
}

export type SubmitAnswerFunction = (answer: string, jump?: number | null) => void;

export default (props: QuestionAndAnswerProps) => {
    const { apiUrl, topic } = useContext(AppContext);

    const [qnaContainerStyle, qnaContainerApi] = useSpring(() => ({
        opacity: 0,
    }));

    const nextQuestion = (questionId: number | undefined, jump?: number | null) => {
        if (jump)
            return jump;

        if (props.voteValue === null)
            return 0;

        const questions: Question[] | undefined = topic?.qa
            ?.sort((q1: Question, q2: Question) => q1.orderIndex - q2.orderIndex)
            ?.filter((question: Question) => {
                if (question.showForRange.length === 0)
                    return true;

                const lowerBound = question.showForRange[0] * 10;
                const upperBound = question.showForRange[1] * 10;

                if (props.voteValue === null)
                    return false;

                return props.voteValue > lowerBound && props.voteValue < upperBound;
            });

        if (!questions)
            return;

        if (questionId === -1)
            return questions[0].questionId;

        for (let i = 0; i < questions.length; i++) {
            if (questions[i].questionId === questionId) {
                let nextI = i + 1;
                while (questions[nextI] && questions[nextI].isJumpOnly) {
                    nextI++;
                }

                if (!questions[nextI]) {
                    props.setVoteEnd(true);
                    break;
                }

                return questions[nextI].questionId;
            }
        }

        return questionId;
    };

    const [state, setState] = useState<QuestionAndAnswerState>({
        qnaAnimationState: QnAAnimationState.ContentPanelFadeIn,
        questionId: nextQuestion(-1),
        questionTime: 0
    });

    const question = topic?.qa.find((question: Question) => question.questionId === state.questionId);

    useEffect(() => {
        qnaContainerApi.start({
            opacity: 1,
            config: { duration: 600 },
            onRest: () => {
                setState({
                    ...state,
                    questionTime: Date.now(),
                    qnaAnimationState: QnAAnimationState.TypeTitleText
                });
            }
        });

    }, [state.questionId]);

    const submitAnswer: SubmitAnswerFunction = async (answer: string, jump?: number | null) => {
        props.firstQuestionAnswered();

        qnaContainerApi.start({
            opacity: 0,
            config: { duration: 300 },
            onRest: () => {
                setState({
                    ...state,
                    questionId: nextQuestion(state.questionId, jump),
                    qnaAnimationState: QnAAnimationState.ContentPanelFadeIn
                });
            }
        });

        await axios.post(`${apiUrl}/qna/${topic?.id}`, {
            question: question.question,
            answer: answer,
            vote: props.voteValue,
            voteId: props.voteId,
            timeToAnswer: !state.questionTime ? 0 : Date.now() - state.questionTime,
        });
    };

    return (
        <animated.div style={qnaContainerStyle}>
            <div style={{ margin: '0 0 20px 0' }}>
                <StyledContentPanel>
                    <TypingText
                        active={state.qnaAnimationState === QnAAnimationState.TypeTitleText}
                        text={question.question}
                        delay={10}
                        onComplete={() => {
                            setState({ ...state, qnaAnimationState: QnAAnimationState.AnswersFadeIn })
                        }}
                    />
                </StyledContentPanel>
            </div>
            {!(state.qnaAnimationState === QnAAnimationState.AnswersFadeIn || state.qnaAnimationState === QnAAnimationState.QuestionFadeOut) ? null
                : question.answerSctructure === 'open'
                    ? <OpenStructure question={question.question} answers={question.answers} submitAnswer={submitAnswer} />
                    : question.answerType === 'multiselect'
                        ? <MultiSelect question={question.question} answers={question.answers} submitAnswer={submitAnswer} />
                        : question.answerType === 'singleselect'
                            ? <SingleSelect question={question.question} answers={question.answers} submitAnswer={submitAnswer} />
                            : null
            }
        </animated.div>
    );
};