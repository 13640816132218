import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import StyledButton from '../../components/StyledButton';
import { Topic } from 'holler-voterapp-model';
import { generateTopicSlug } from '../../util/util';
import { AppContext } from '../../App';
import axios, { AxiosResponse } from 'axios';
import TopicCard from '../../components/TopicCard';

import styled from 'styled-components';
import Fade from '../../components/Fade';
import { animated, useSpring } from 'react-spring';
import { useHistory } from 'react-router-dom';
interface VoteEndProps {
    resetVote: () => void;
}

const VoteEnd: React.FC<VoteEndProps> = ({ resetVote }) => {

    const { apiUrl, topic, setGlassPanelBackdropFilter } = useContext(AppContext);

    const [topics, setTopics] = useState<Topic[] | null>(null);

    const history = useHistory();

    const [voteEndContainerStyle, voteEndContainerApi] = useSpring(() => ({
        opacity: 1
    }));

    useEffect(() => {
        if (!topic)
            return;

        setGlassPanelBackdropFilter(0, 1, 1);

        const topicSlug = generateTopicSlug(topic.tn);

        axios.get(`${apiUrl}/topic/${topicSlug}/children`).then((res: AxiosResponse) => {
            setTopics(res.data.msgList);
        });
    }, []);

    const topicCardClicked = (topic: Topic) => {
        voteEndContainerApi.start({
            opacity: 0,
            config: { duration: 300 },
            onRest: () => {
                if (topic) {
                    resetVote();
                    history.push(generateTopicSlug(topic.tn));
                }
            }
        });
    };

    return (
        <animated.div style={voteEndContainerStyle}>
            {!topic?.pvt.showVoteAgain ? null : <StyledButton onClick={resetVote}>Гласувайте пак на тази тема</StyledButton>}
            {!topic?.pvt.showCopyLink ? null : <StyledButton onClick={resetVote}>Копирайте линка за гласуване</StyledButton>}
            {!topics ? null :
                <Fade cascade>
                    {topics.map((topic, i) => <TopicCard key={i} topic={topic} onClick={() => topicCardClicked(topic)} />)}
                </Fade>
            }
        </animated.div>
    );
};

export default VoteEnd;