import { useContext } from "react";

import Fade from "../components/Fade";
import StyledButton from "../components/StyledButton";
import { AppContext, MenuScreen } from "../App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";

import StyledContentPanel from "../components/StyledContentPanel";

const MainMenu: React.FC = () => {

    const { setMenuScreen } = useContext(AppContext);

    const aboutUsClicked = () => {
        window.open('https://business.holler.live', '_blank', 'noopener,noreferrer');
    };

    return (
        <div style={{ padding: '20px' }}>
            <Fade cascade>
                <StyledContentPanel style={{ marginBottom: '20px' }}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faGear} /></span>
                    Меню
                </StyledContentPanel>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.Profile)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faUser} /></span>
                    Профил
                </StyledButton>
                <StyledButton onClick={aboutUsClicked}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faBuilding} /></span>
                    За нас
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.Language)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faLanguage} /></span>
                    Език
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.PrivacyNoticeMenu)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faLock} /></span>
                    Политика за поверителност
                </StyledButton>
                <StyledButton onClick={() => setMenuScreen(MenuScreen.None)}>
                    <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCircleXmark} /></span>
                    Затвори меню
                </StyledButton>
            </Fade>
        </div>
    );
};

export default MainMenu;