import { Answer } from 'holler-voterapp-model';

import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';

import OptionButton from '../../../components/OptionsButton';

import Fade from '../../../components/Fade';

interface AnswerSingleSelectProps {
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

export default (props: AnswerSingleSelectProps) => {

    const buttonClicked = (answer: Answer) => {
        if (answer.link) {
            window.open(answer.link, '_blank');
        }

        if (answer.nextQuestionId)
            props.submitAnswer(answer.text, answer.nextQuestionId);
        else
            props.submitAnswer(answer.text);
    };

    return (<>
        <Fade cascade>
            {props.answers.map((answer: Answer, i: number) =>
                <StyledButton key={i} onClick={() => buttonClicked(answer)}>
                    {answer.link ? '🌐' : null} {answer.text}
                    {/*<FontAwesomeIcon icon={faGlobe}/> {answer.text}*/}
                </StyledButton>
            )}
            <OptionButton/>
        </Fade>
    </>);
};