import styled from "styled-components";

import { GroupResult } from "./VoteResult";

const SlideCardContainer = styled.div`
    padding: 10px 0;
    height: 100%;
`;

const SlideCard = styled.div`
    background-color: #00000055;
    padding: 20px;
    color: white;
    border-radius: 20px;
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    box-sizing: border-box;
    margin-bottom: 20px;
    width: calc(100% - 20px);
    vertical-align: top;
    height: 100%;
    border: 3px solid transparent;
    transition: background-color 1s ease, border 1s ease;

    h1 {
        margin: 0;
        font-size: 24px;
    }

    p {
        font-size: 20px;
        color: white;
        margin-top: 15px;
        margin-bottom: 0;

        &.auxiliary {
            font-size: 16px;
        }
    }
`;

const ReferenceRatingLine = styled.div`
    width: 20px;
    height: calc(100% - 40px);
    display: inline-block;
    margin: 20px 0px 0px 0px;

    .container {
        position: relative;
        width: 570px;
        margin-left: -550px;
        height: 10px;
        z-index: -2;
    }

    .large-part {
        height: 100%;
        display: inline-block;
        vertical-align: top;
    }

    .small-part {
        height: 100%;
        display: inline-block;
        vertical-align: top;
    }

    ${(props: { showLine: boolean, lineColor: string}) => `

        .large-part {
            margin-left: ${props.showLine ? 0 : '550px'};
            width: ${props.showLine ? '550px' : 0};
            background-color: ${props.lineColor};
            transition: ${props.showLine
                ? 'width 0.5s linear 0.3s, margin-left 0.5s linear 0.3s'
                : 'width 0.5s linear, margin-left 0.5s linear'
            };
        }

        .small-part {
            margin-left: ${props.showLine ? 0 : '20px'};
            width: ${props.showLine ? '20px' : 0};
            background-color: ${props.lineColor};
            transition: ${props.showLine
                ? 'width 0.3s linear, margin-left 0.3s linear'
                : 'width 0.3s linear 0.5s, margin-left 0.3s linear 0.5s'
            };
        }
    `}

`;

interface ResultSlideProps {
    slideClicked: (slideNumber: number) => void;
    groupResult: GroupResult;
    currentSlide: number;
    index: number;
    children: React.ReactNode;
}

const ResultSlide: React.FC<ResultSlideProps> = (props) => {

    const showLine = props.index === props.currentSlide && props.groupResult.voteCount !== null;
    const lineColor = props.groupResult.color;

    const rating = props.groupResult.rating ?? 50;

    return (
        <SlideCardContainer onClick={() => props.slideClicked(1)}>
            <ReferenceRatingLine showLine={showLine} lineColor={lineColor}>
                <div className='container' style={{
                top: `calc(${1 - (rating - 1) / 99} * (100% - 10px))`,
                }}>
                    <div className='large-part'/>
                    <div className='small-part'/>
                </div>
            </ReferenceRatingLine>
            <SlideCard style={{ backgroundColor: `${lineColor}4d`, border: `3px solid ${lineColor}80` }}>
                {props.children}
            </SlideCard>
        </SlideCardContainer>
    );
};

export default ResultSlide;