import React, { useMemo } from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const TypingTextStyle = styled.div`
    //background: red;
    transition: height 1s ease;

    span.visible-part {

    }

    span.transparent-part {
        opacity: 0;
    }
`;

interface TypingTextProps {
    delay?: number;
    active?: boolean;
    onComplete?: () => void;
    children: React.ReactNode;
}

const TypingText: React.FC<TypingTextProps> = (props) => {


    //const visiblePartRef = useRef<HTMLSpanElement>(null);
    //const transparentPartRef = useRef<HTMLSpanElement>(null);

    //const textRef = useRef<string>(props.text);

    const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);

    const itemRefs = useRef<Array<HTMLSpanElement | null>>([]);
    //const cursorRef = useRef<number>(0);
    const currentSpanRef = useRef<number>(0);

    const typingLoop = () => {
        timeoutRef.current = setTimeout(() => {
            const visiblePartRef = itemRefs.current[currentSpanRef.current];
            const transparentPartRef = itemRefs.current[currentSpanRef.current + 1];

            if (!visiblePartRef || !transparentPartRef)
                return;

            if (visiblePartRef.textContent === null || transparentPartRef.textContent === null)
                return;

            if (transparentPartRef.textContent.length > 0) {
                const nextLetter = transparentPartRef.textContent[0];

                visiblePartRef.textContent += nextLetter;
                transparentPartRef.textContent = transparentPartRef.textContent.slice(1);

                typingLoop();
            } else if (currentSpanRef.current + 2 < itemRefs.current.length) {

                currentSpanRef.current += 2;
                typingLoop();

            } else {
                timeoutRef.current = null;
                if (props.onComplete && typeof props.onComplete === 'function')
                    props.onComplete();
            }

        }, props.delay ?? 20);
    };

    /*useEffect(() => {
        textRef.current = props.text;
        cursorRef.current = 0;

        if (!timeoutRef.current) {
            if (props.active === undefined || props.active === true) {
                typingLoop();
            }
        }

    }, [props.text]);*/

    useEffect(() => {
        if (props.active === undefined || props.active === true) {
            typingLoop();
        }
    }, [props.active]);

    let counter = 0;

    const modifyText = (child: React.ReactNode): string | React.ReactNode => {
        if (typeof child === 'string' || typeof child === 'number') {
            const childString: string = typeof child === 'number' ? child.toString() : child;
            return [
                <span ref={el => itemRefs.current[counter++] = el} className='visible-part'></span>,
                <span ref={el => itemRefs.current[counter++] = el} className='transparent-part'>{childString}</span>
            ];
        } else if (React.isValidElement(child)) {
            return React.cloneElement(child, child.props, React.Children.map(child.props.children, modifyText));
        } else {
            return child;
        }
    };

    return (useMemo(() =>
        <TypingTextStyle>
            {React.Children.map(props.children, modifyText)}
        </TypingTextStyle>,
        []
    ));
};

export default TypingText;