import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import TopicComponent from './pages/Topic';

import styled, { createGlobalStyle } from 'styled-components';
import { Topic } from 'holler-voterapp-model';
import { getCookie, setCookie } from './util/cookies';

import RotateMe from './components/RotateMe';
import PolicyScreen from './menu/PolicyScreen';
import ConsentScreen from './menu/ConsentScreen';
import GlassPanel from './components/GlassPanel';
import MainMenu from './menu/MainMenu';
import ProfileComponent from './menu/Profile';
import LanguageScreen from './menu/LanguageScreen';

const GlobalStyle = createGlobalStyle`
    body, input, button {
        margin: 0;
        //font-family: Raleway, sans-serif;
        font-family: sans-serif;
    }

    select, option {
        //font-family: Raleway, sans-serif;
    }

    h3 {
        font-size: 28px;
        font-weight: bold;
    }
    
    p {
        line-height: 1.7;
        font-size: 22px;
        font-weight: 300;
        color: #333;
        b {
            font-weight: 999;
        }
    }
    a:active, a:focus {
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }
`;

const BackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    transition: filter 0.6s ease;
`;

const BackgroundImage = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    z-index: -2;
    //filter: blur(40px);
`;

const AppContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;

export type GenderData = 'male' | 'female' | 'other' | null;

export enum MenuScreen {
    None = 'none',
    MainMenu = 'main-menu',
    Language = 'language',
    Profile = 'profile',
    PrivacyNotice = 'privacy-notice',
    PrivacyNoticeMenu = 'privacy-notice-menu',
    Consent = 'consent'
}

export interface ProfileData {
    userId: string;
    locale: string;
    name: string;
    gender: GenderData;
    birthYear: number | null;
    cityName: string;
    regionName: string;
    countryName: string;
}

const emptyProfile: ProfileData = {
    userId: '',
    locale: '',
    name: '',
    gender: null,
    birthYear: null,
    cityName: '',
    regionName: '',
    countryName: ''
};

interface AppContextType {
    apiUrl: string;
    profile: ProfileData;
    setProfile: (profile: ProfileData | null) => void;
    topic: Topic | null;
    setTopic: (topic: Topic | null) => void;
    cookieConsent: true | false | null;
    giveCookieConsent: () => void;
    menuScreen: MenuScreen;
    setMenuScreen: (menuScreen: MenuScreen) => void;
    setGlassPanelColor: (color: string) => void;
    setGlassPanelBackdropFilter: (blur: number, saturate: number, brightness: number) => void;
}

export const AppContext = React.createContext<AppContextType>({
    apiUrl: '',
    profile: emptyProfile,
    setProfile: () => { throw new Error("Function not implemented"); },
    topic: null,
    setTopic: () => { throw new Error("Function not implemented"); },
    cookieConsent: null,
    giveCookieConsent: () => { throw new Error("Function not implemented"); },
    menuScreen: MenuScreen.None,
    setMenuScreen: () => { throw new Error("Function not implemented"); },
    setGlassPanelColor: () => { throw new Error("Function not implemented") },
    setGlassPanelBackdropFilter: () => { throw new Error("Function not implemented") },
});

interface AppState {
    profile: ProfileData | null;
    topic: Topic | null;
    cookieConsent: boolean;
    menuScreen: MenuScreen;
}

export default () => {
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_STAGING
            ? 'https://staging-api.kazva.bg/api/v1'
            : 'https://prod.kazva.bg/api/v1'
        //: 'http://192.168.1.3:8000/api/v1';
        : 'http://localhost:8000/api/v1';

    /*const [profile, setProfile] = useState<ProfileData | null>(null);
    const [topic, setTopic] = useState<Topic | null>(null);
    const [cookieConsent, setCookieConsent] = useState<boolean>(getCookie('cookieConsent') === "true");
    const [policyScreenOpen, setPolicyScreenOpen] = useState(false);*/

    const glassPanelRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<AppState>({
        profile: null,
        topic: null,
        cookieConsent: getCookie('cookieConsent') === "true",
        menuScreen: getCookie('cookieConsent') === "true" ? MenuScreen.None : MenuScreen.Consent
    });

    useEffect(() => {
        if (!state.profile) {
            axios.get(`${apiUrl}/user`).then(res => {
                setState({ ...state, profile: res.data.profile });
            });
        }
    }, [state.profile]);

    const giveCookieConsent = () => {
        setCookie('cookieConsent', 'true', 365);
        setState({ ...state, cookieConsent: true, menuScreen: MenuScreen.None });
    };

    const setGlassPanelColor = (color: string) => {
        if (glassPanelRef.current) {
            glassPanelRef.current.style.backgroundColor = color;
        }
    };

    const setGlassPanelBackdropFilter = (blur: number, saturate: number, brightness: number) => {
        if (glassPanelRef.current) {
            const styleObject = glassPanelRef.current.style as any;
            styleObject.backdropFilter = `blur(${blur}px) saturate(${saturate}) brightness(${brightness})`;
            styleObject['-webkit-backdrop-filter'] = `blur(${blur}px) saturate(${saturate}) brightness(${brightness})`;
        }
    };

    const renderMenuScreen = () => {
        switch (state.menuScreen) {
            case MenuScreen.None:
                return null;
            case MenuScreen.Consent:
                return <ConsentScreen />;
            case MenuScreen.PrivacyNotice:
                return <PolicyScreen prevScreen={MenuScreen.Consent} />;
            case MenuScreen.PrivacyNoticeMenu:
                return <PolicyScreen prevScreen={MenuScreen.MainMenu} />;
            case MenuScreen.MainMenu:
                return <MainMenu />;
            case MenuScreen.Profile:
                return <ProfileComponent />;
            case MenuScreen.Language:
                return <LanguageScreen />

            default: return null;
        }
    };

    console.log('UPDATING APP STATE');

    return (
        !state.profile ? null : //<h5>Зареждане на профил...</h5> :
            <AppContext.Provider value={{
                apiUrl,
                profile: state.profile,
                setProfile: (newProfile) => setState({ ...state, profile: newProfile }),
                topic: state.topic,
                setTopic: (newTopic) => setState({ ...state, topic: newTopic }),
                cookieConsent: state.cookieConsent,
                giveCookieConsent,
                menuScreen: state.menuScreen,
                setMenuScreen: (newMenuScreen: MenuScreen) => setState({ ...state, menuScreen: newMenuScreen }),
                setGlassPanelColor, setGlassPanelBackdropFilter,
            }}>
                <GlobalStyle />
                <RotateMe />
                <AppContainer>
                    <BackgroundContainer>
                        <BackgroundImage src={state.topic?.ti ? state.topic.ti : '/images/gradient-backgrounds/GradientTextures-04.jpg'} />
                    </BackgroundContainer>
                    <GlassPanel ref={glassPanelRef}>
                        {renderMenuScreen()}
                        <div style={{ width: '100%', height: '100%', display: state.menuScreen === MenuScreen.None ? 'block' : 'none '}}>
                            <BrowserRouter>
                                <Switch>
                                    <Route path='/:topicHash' component={TopicComponent} />
                                    <Route exact path='/' component={Home} />
                                </Switch>
                            </BrowserRouter>
                        </div>
                    </GlassPanel>
                </AppContainer>
            </AppContext.Provider>
    );
};
