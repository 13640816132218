import React, { useEffect, useRef, useState } from 'react';

import { Answer } from 'holler-voterapp-model';
import UploadPhoto from './UploadPhoto';
import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';
import StyledTextArea from '../../../components/StyledTextArea';
import Fade from '../../../components/Fade';
import OptionsButton from '../../../components/OptionsButton';

interface AnswerOpenStructureProps {
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
}

export type SetImageFunction = (i: number, imageUrl: string) => void;
export type DeleteImageFunction = (i: number) => void;

export default (props: AnswerOpenStructureProps) => {
    const [values, setValues] = useState<Array<{ text: string; image: string | null; }>>(props.answers.map(() => ({ text: '', image: null })));

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const submitAnswer = (answer: Answer) => {
        if (values.length > 1) {

            let answersString = '';
            for (let i = 0; i < values.length; i++) {
                if (values[i]) {
                    answersString += `${!values[0].image? '' : values[0].image + ' '}${values[i].text}/%/`
                }
            }

            props.submitAnswer(answersString);
        } else if (values.length === 1) {
            props.submitAnswer(`${!values[0].image? '' : values[0].image + ' '}${values[0].text}`, answer.nextQuestionId);
        } else {
            props.submitAnswer('');
        }
    };

    const handleChange = (i: number, e: React.ChangeEvent<HTMLTextAreaElement>) => {
        values[i].text = e.target.value;
        setValues([...values]);

        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
            textAreaRef.current.style.overflowY = 'hidden';
        }
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
            textAreaRef.current.style.overflowY = 'hidden';
        }
    }, [textAreaRef.current]);

    const setImage: SetImageFunction = (i, imageUrl) => {
        values[i].image = imageUrl;
        setValues([...values]);
    };

    const deleteImage = (i: number) => {
        values[i].image = '';
        setValues([...values]);
    };

    return (
        <Fade cascade>
            {props.answers.map((answer: Answer, i: number) =>
                <label key={i}>
                    {answer.text}
                    <StyledTextArea ref={textAreaRef} onChange={e => handleChange(i, e)} placeholder={answer.placeholderText} />
                    {!answer.canAttachImage ? null : <UploadPhoto setImage={setImage} deleteImage={deleteImage} value={values[i]} number={i} />}
                    <StyledButton onClick={() => submitAnswer(answer)}>Потвърди</StyledButton>
                </label>
            )}
            <OptionsButton/>
        </Fade>
    );
};