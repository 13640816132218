import styled from 'styled-components';

import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { AppContext, MenuScreen } from '../App';

const OptionsButtonStyle = styled.button`
    color: white;
    font-size: 42px;
    background: #00000055;
    border: none;
    border-radius: 50px;
    padding: 10px;
    width: 65px;
    height: 65px;
    opacity: 0.5;
    cursor: pointer;

    ${(props: { absolute?: boolean }) => props.absolute ? `
        position: absolute;
        right: 30px;
        bottom: 30px;
    ` : `
        margin-left: calc(100% - 65px);
    `}
`;

interface OptionsButtonProps {
    absolute?: boolean;
}

const OptionsButton: React.FC<OptionsButtonProps> = (props) => {
    const { setMenuScreen } = useContext(AppContext);

    return (
        <OptionsButtonStyle absolute={props.absolute} onClick={() => setMenuScreen(MenuScreen.MainMenu)}>
            <FontAwesomeIcon icon={faGear} />
        </OptionsButtonStyle>
    );
};

export default OptionsButton;